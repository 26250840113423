import _split from "lodash-es/split";
import React, { useMemo } from 'react';
import { Document, Font, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { floorSeconds, format as formatDuration, normalizeTime } from 'pomeranian-durations';
import { useTranslation } from '@/common/hooks';
import { formatByteValue } from '@/common/utils/format-bytes';
import interstateBold from '@assets/fonts/Interstate-Bold.ttf';
import interstateLight from '@assets/fonts/Interstate-Light.ttf';
import robotoLight from '@assets/fonts/roboto-light-webfont.ttf';
import rubikBold from '@assets/fonts/Rubik-Bold.ttf';
import rubikLight from '@assets/fonts/Rubik-Light.ttf';
import { jsx as ___EmotionJSX } from "@emotion/core";
Font.register({
  family: 'Rubik',
  fonts: [{
    src: rubikLight
  }, {
    src: rubikBold,
    fontStyle: 'normal',
    fontWeight: 700
  }]
});
Font.register({
  family: 'Roboto',
  src: robotoLight
});
Font.register({
  family: 'Interstate',
  fonts: [{
    src: interstateLight
  }, {
    src: interstateBold,
    fontStyle: 'normal',
    fontWeight: 700
  }]
});

var styles = function styles(_ref) {
  var backgroundColor = _ref.backgroundColor,
      fontColor = _ref.fontColor,
      fontFamily = _ref.fontFamily;
  return StyleSheet.create({
    page: {
      paddingTop: 58,
      paddingLeft: 55,
      paddingRight: 55,
      paddingBottom: 58,
      backgroundColor: backgroundColor,
      color: fontColor,
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 12,
      lineHeight: 1.5
    },
    logo: {
      width: 140,
      maxHeight: 100,
      objectFit: 'contain',
      borderWidth: 0
    },
    title: {
      marginTop: 20,
      fontSize: 60,
      lineHeight: 0.9,
      fontFamily: fontFamily,
      fontWeight: 700
    },
    body: {
      marginTop: 38
    },
    columns: {
      flexDirection: 'row'
    },
    column: {
      flexGrow: 1,
      maxWidth: 243
    },
    row: {
      marginTop: 18
    },
    artworkTitle: {
      fontSize: 27,
      lineHeight: 1,
      fontFamily: fontFamily,
      fontWeight: 700,
      paddingRight: 20
    },
    strong: {
      fontFamily: fontFamily,
      fontWeight: 700
    },
    artworkThumbnail: {
      width: '100%',
      maxWidth: 243,
      marginLeft: 'auto',
      height: 'auto'
    },
    footer: {
      marginTop: 38
    },
    qrCodeContainer: {
      marginRight: 31
    },
    qrCode: {
      width: 120,
      height: 'auto'
    },
    blockchainContainer: {
      lineHeight: 1.9
    },
    blockchainLabel: {
      marginTop: 24
    },
    blockchainText: {
      lineHeight: 1,
      fontFamily: 'Roboto'
    }
  });
};

var Certificate = function Certificate(_ref2) {
  var _certificate$blockcha, _artwork$artist, _artwork$artist2;

  var collection = _ref2.collection,
      qrCodeSrc = _ref2.qrCodeSrc,
      theme = _ref2.theme;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var artwork = collection.artwork,
      certificate = collection.certificate,
      token = collection.token,
      distinctEdition = collection.distinctEdition;
  var thumbnailUrl = artwork === null || artwork === void 0 ? void 0 : artwork.thumbnailUrl;
  var blockchain = ((_certificate$blockcha = certificate.blockchainTransaction) === null || _certificate$blockcha === void 0 ? void 0 : _certificate$blockcha.transactionHash) || '';
  var tokenBlockExplorerUrl = (token === null || token === void 0 ? void 0 : token.tokenBlockExplorerUrl) || '';
  var blockchainLineSize = 50;
  var blockchainLines = blockchain.match(new RegExp('.{1,' + blockchainLineSize + '}', 'g'));
  var certificateTheme = useMemo(function () {
    return styles({
      backgroundColor: theme.backgroundColor,
      fontColor: theme.fontColor,
      fontFamily: theme.fontFamily
    });
  }, [theme.backgroundColor, theme.fontColor, theme.fontFamily]);
  return ___EmotionJSX(Document, null, ___EmotionJSX(Page, {
    size: "A4",
    style: certificateTheme.page
  }, ___EmotionJSX(Image, {
    style: certificateTheme.logo,
    source: theme.logo
  }), ___EmotionJSX(Text, {
    style: certificateTheme.title
  }, t('certificate.headline1')), ___EmotionJSX(Text, {
    style: certificateTheme.title
  }, t('certificate.headline2')), ___EmotionJSX(View, {
    style: certificateTheme.body
  }, ___EmotionJSX(View, {
    style: certificateTheme.columns
  }, ___EmotionJSX(View, {
    style: certificateTheme.column
  }, ___EmotionJSX(Text, null, t('certificate.artworkTitle')), ___EmotionJSX(Text, {
    style: certificateTheme.artworkTitle
  }, distinctEdition !== null && distinctEdition !== void 0 && distinctEdition.title ? distinctEdition === null || distinctEdition === void 0 ? void 0 : distinctEdition.title : artwork === null || artwork === void 0 ? void 0 : artwork.title), ___EmotionJSX(View, {
    style: certificateTheme.row
  }, ___EmotionJSX(Text, null, t('certificate.artistSeller')), ___EmotionJSX(Text, {
    style: certificateTheme.strong
  }, artwork === null || artwork === void 0 ? void 0 : (_artwork$artist = artwork.artist) === null || _artwork$artist === void 0 ? void 0 : _artwork$artist.firstName, "\xA0", artwork === null || artwork === void 0 ? void 0 : (_artwork$artist2 = artwork.artist) === null || _artwork$artist2 === void 0 ? void 0 : _artwork$artist2.lastName)), ___EmotionJSX(View, {
    style: certificateTheme.row
  }, ___EmotionJSX(Text, null, t('certificate.issuer')), ___EmotionJSX(Text, {
    style: certificateTheme.strong
  }, t('certificate.issuerElementum'))), ___EmotionJSX(View, {
    style: certificateTheme.row
  }, ___EmotionJSX(View, {
    style: certificateTheme.columns
  }, ___EmotionJSX(View, {
    style: certificateTheme.column
  }, ___EmotionJSX(Text, null, t('artwork.editionNumber')), ___EmotionJSX(Text, null, t('artwork.productionYear')), ___EmotionJSX(Text, null, t('artwork.resolution')), ___EmotionJSX(Text, null, t('artwork.fileFormat')), ___EmotionJSX(Text, null, t('artwork.fileSize')), artwork.duration && ___EmotionJSX(Text, null, t('artwork.duration'))), ___EmotionJSX(View, {
    style: certificateTheme.column
  }, ___EmotionJSX(Text, null, t('artwork.editionNumberValue', {
    editionNumber: certificate.editionNumber,
    editionSize: artwork.editionSize
  })), ___EmotionJSX(Text, null, _split(artwork.productionDate, '-')[0]), ___EmotionJSX(Text, null, artwork.width, "\xA0\xD7\xA0", artwork.height, "\xA0px"), ___EmotionJSX(Text, null, artwork.fileFormat.toLocaleLowerCase()), ___EmotionJSX(Text, null, formatByteValue(artwork.fileSize)), artwork.duration && ___EmotionJSX(Text, null, formatDuration('%m min %s sec', floorSeconds(normalizeTime(artwork.duration)))))))), ___EmotionJSX(View, {
    style: certificateTheme.column
  }, ___EmotionJSX(Image, {
    style: certificateTheme.artworkThumbnail,
    src: (distinctEdition ? distinctEdition === null || distinctEdition === void 0 ? void 0 : distinctEdition.thumbnailUrl : thumbnailUrl) + "?noCache=" + Math.random().toString()
  })))), ___EmotionJSX(View, {
    style: certificateTheme.footer
  }, ___EmotionJSX(View, {
    style: certificateTheme.columns
  }, ___EmotionJSX(View, {
    style: certificateTheme.qrCodeContainer
  }, ___EmotionJSX(Image, {
    style: certificateTheme.qrCode,
    source: qrCodeSrc
  })), ___EmotionJSX(View, {
    style: certificateTheme.blockchainContainer
  }, ___EmotionJSX(Text, null, t('certificate.timestamp')), ___EmotionJSX(Text, {
    style: certificateTheme.strong
  }, format(parseISO(certificate.creationDateTime), 'PPPPpppp')), ___EmotionJSX(Text, {
    style: certificateTheme.blockchainLabel
  }, t('certificate.blockchainTxHash')), blockchainLines === null || blockchainLines === void 0 ? void 0 : blockchainLines.map(function (item, index) {
    return ___EmotionJSX(Link, {
      src: tokenBlockExplorerUrl,
      style: certificateTheme.blockchainText,
      key: 'blockchainLine' + index
    }, item);
  }))))));
};

Certificate.displayName = "Certificate";
export { Certificate };